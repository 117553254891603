import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CprsSearch } from '@src/app/cprs/services/cprs.service';
import { StorageService } from '@src/app/cprs/services/storage.service';
import { TemplateService } from '@src/app/cprs/services/template.service';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { StateService } from '@src/app/cprs/state/state.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { ReplaySubject } from 'rxjs';
import { CdTableData } from '../../table/models/cprs-table-grid.interface';
import { CdSearchService } from '@src/app/cprs/services/search.service';
import { map } from 'rxjs/operators';
import { IdleService } from '../../system/services/idle.service';

export interface CprsRecentSearch {
  search_term: string;
  field_heading: string;
  filters_added: string[];
  number_of_results: number;
  timestamp: number;
  date_string: string;
  url: string;
}

export interface CprsRecentRecord {
  full_title: string;
  copyright_number: string;
  representative_date: number;
  date_viewed: string;
  control_number: string;
  describer: any;
  timestamp: any;
  url: string;
}

export interface CprsRecentRecordData {
  control_number: CdTableData;
  full_title: CdTableData;
  copyright_number: CdTableData;
  representative_date: CdTableData;
  acquisition_type: CdTableData;
  type_of_work: CdTableData;
  party_1: CdTableData;
  publisher_name: CdTableData;
  claimant: CdTableData;
  party_2: CdTableData;
  publication_date: CdTableData;
  service_request_number: CdTableData;
  highlights: CdTableData;
  url: CdTableData;
  type_of_record: CdTableData;
  timestamp: CdTableData;
  date_viewed: CdTableData;
  simple_record: CdTableData;
  unix_timestamp: CdTableData;
}

@Injectable({
  providedIn: 'root',
})
export class RecentFeatureService {
  public recentSearches: CprsRecentSearch[] = [];
  public recentRecords: CprsRecentRecordData[] = [];

  public recentRecordsControlNumbers: { control_number: string, saved_on: string, unix_timestamp: number }[] = [];
  public recentRecordsSubject = new ReplaySubject();

  public currentRecentSearches: ReplaySubject<CprsRecentSearch[]> = new ReplaySubject();
  public currentRecentRecords: ReplaySubject<CprsRecentRecordData[]> = new ReplaySubject();

  constructor(
    private datePipe: DatePipe,
    public storageService: StorageService,
    public stateService: StateService,
    public templateService: TemplateService,
    public cdSearchService: CdSearchService,
    public envService: EnvService,
    public idle: IdleService
  ) {
    this.idle.createInactivityTimer(
      'recent-expiration',
      1000,
      4 * 60 * 60 * 1000 // 4 hours
    ).subscribe(() => {
      this.storageService.removeOutdatedItems('cprs-recent-searches', 0);
      this.recentSearches = this.storageService.getArray('cprs-recent-searches');
      this.currentRecentSearches.next(this.recentSearches);

      this.storageService.removeOutdatedItems('cprs-recent-records-ids', 0);
      this.recentRecordsControlNumbers = this.storageService.getArray('cprs-recent-records-ids');
      this.recentRecordsSubject.next(this.recentRecordsControlNumbers);
    });

    this.expireRecentRecords();
    this.expireRecentSearches();
  }

  expireRecentSearches() {
    this.storageService.removeOutdatedItems('cprs-recent-searches', 4);
    this.recentSearches = this.storageService.getArray('cprs-recent-searches');
    this.currentRecentSearches.next(this.recentSearches);
  }

  expireRecentRecords() {
    this.storageService.removeOutdatedItems('cprs-recent-records-ids', 4);
    this.recentRecordsControlNumbers = this.storageService.getArray('cprs-recent-records-ids');
    this.recentRecordsSubject.next(this.recentRecordsControlNumbers);
  }

  removeRecentRecords(recent_records: CprsRecentRecordData[]) {
    const control_numbers = new Set(recent_records.map(r => r.control_number.value));

    const filtered = this.recentRecordsControlNumbers.filter(cn => !control_numbers.has(cn.control_number));
    this.recentRecordsControlNumbers = filtered;
    this.storageService.setArray('cprs-recent-records-ids', filtered);
  }

  removeRecentSearches(recent_searches: CprsRecentSearch[]) {
    const timestamps = new Set(recent_searches.map((rs) => rs.timestamp['value']));
    const queries = new Set(recent_searches.map((rs) => rs.search_term['value']));
    const filtered = this.recentSearches.filter((rs) => !timestamps.has(rs.timestamp) && !queries.has(rs.search_term));
    this.recentSearches = filtered;
    this.storageService.setArray('cprs-recent-searches', filtered);
    this.currentRecentSearches.next(filtered);
  }

  getRecentRecordsFromService() {
    const control_numbers = this.recentRecordsControlNumbers.map(rr => rr.control_number);
    return this.cdSearchService.getUniqueRecords(control_numbers).pipe(
      map(resp => {
        resp.forEach((r: any) => {
          const saved_on = this.recentRecordsControlNumbers.find(cn => cn.control_number === r.get('control_number'));
          r.set('saved_on', saved_on?.saved_on);
          r.set('unix_timestamp', saved_on?.unix_timestamp);
        });

        return resp;
      })
    )
  }

  getRecentRecordsList() {
    const control_numbers = this.recentRecordsControlNumbers.map(rr => rr.control_number);
    return this.cdSearchService.displayUniqueRecords(control_numbers).pipe(
      map((resp: any) => {
        resp.data?.forEach((r: any) => {
          const saved_on = this.recentRecordsControlNumbers.find(cn => cn.control_number === r.get('control_number'));
          r.set('saved_on', saved_on?.saved_on);
          r.set('unix_timestamp', saved_on?.unix_timestamp);
        });
        resp.data?.sort((a: any, b: any) => {
          const timestampA = a.get('unix_timestamp');
          const timestampB = b.get('unix_timestamp');
          if (timestampB < timestampA) {
            return -1;
          }
          if (timestampB > timestampA) {
            return 1;
          }
          return 0;
        });

        return resp;
      }));
  }

  getRecentRecords(): ReplaySubject<CprsRecentRecordData[]> {
    return this.currentRecentRecords;
  }

  getRecentSearches(): ReplaySubject<CprsRecentSearch[]> {
    return this.currentRecentSearches;
  }

  createRecentRecord(record: any) {
    const describer = new ElasticSearchResult(record);
    const timestamp = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm a') ?? new Date().toDateString();

    const tableData: CprsRecentRecordData = this.getData(describer);
    tableData.timestamp.value = timestamp;
    tableData.unix_timestamp.value = Date.now()

    // const recent_record: CprsRecentRecord = {
    //   full_title: describer.getTitle(),
    //   copyright_number: describer.getCopyrightNumber(),
    //   representative_date: describer.get('representative_date'),
    //   date_viewed: timestamp,
    //   control_number: describer.get('control_number'),
    //   describer: describer.getSimpleRecord(),
    //   timestamp,
    //   url: describer.getHref(),
    // };

    this.recentRecords = this.recentRecords.filter((s) => s.control_number.value !== tableData.control_number.value);
    const control_number = describer.get('control_number');
    const saved_on: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm a');
    const rr = {
      control_number,
      timestamp: Date.now(),
      unix_timestamp: Date.now(),
      saved_on
    }

    this.recentRecordsControlNumbers = this.recentRecordsControlNumbers.filter(cn => cn.control_number !== control_number);
    this.recentRecordsControlNumbers.push(rr);
    this.storageService.setArray('cprs-recent-records-ids', this.recentRecordsControlNumbers);
    this.recentRecordsSubject.next(this.recentRecordsControlNumbers);

    this.recentRecords.push(tableData);
    this.storageService.setArray('cprs-recent-records', this.recentRecords);
    this.currentRecentRecords.next(this.recentRecords);
  }

  createRecentSearch(search: CprsSearch): CprsRecentSearch {
    // get the necessary data
    let search_term = search.response.metadata.query;
    let adv_field_heading = '';
    if (Array.isArray(search_term)) {
      const foundSearchTypes = new Set(search_term.map((q) => q.column_name));
      const searchTypeLabels = this.stateService.mega_mini_menu_array.filter((q) => foundSearchTypes.has(q.value));
      adv_field_heading = searchTypeLabels.map((q) => q.text).join(', ');
      search_term = search_term.map((q) => q['query']).join(', ');
    }

    let field_heading = '';
    if (search.type === 'simple') {
      const simple_search_field_heading =
        this.stateService.basic_search_options.find((c) => c.value === JSON.parse(search.parameters.field_type))
          ?.text ?? 'Keyword';
      field_heading = simple_search_field_heading;
    } else if (search.type === 'advanced') {
      field_heading = adv_field_heading;
    }

    const filters_added = search.metadata?.filters_added.map((i) => i.label) ?? [];

    const number_of_results = search.response.metadata.hit_count;
    const timestamp = Date.now();
    const date_string = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm a') ?? new Date().toTimeString();

    // create the recent search
    const recent_search: CprsRecentSearch = {
      search_term,
      field_heading,
      filters_added,
      number_of_results,
      timestamp,
      date_string,
      url: search.url,
    };
    // check for duplicate by search_term && field_heading
    const duplicate: CprsRecentSearch | undefined = this.recentSearches.find(
      (s) => s.search_term === recent_search.search_term && s.field_heading === recent_search.field_heading
    );

    if (duplicate) {
      const timestamp = Date.now();
      const date_string = this.datePipe.transform(new Date(), 'yyyy-dd-MM hh:mm a') ?? new Date().toTimeString();

      duplicate.timestamp = timestamp;
      duplicate.date_string = date_string;
    } else {
      this.recentSearches.push(recent_search);

      this.storageService.addItemToArray('cprs-recent-searches', recent_search);
    }

    this.currentRecentSearches.next(this.recentSearches);
    this.storageService.setArray('cprs-recent-searches', this.recentSearches);

    return recent_search;
  }

  getData(esr: ElasticSearchResult) {
    let typeOfWorkVal = esr.findFirst(['type_of_work_to_english', 'cc_type_of_work']);
    if (typeOfWorkVal === 'Cancelled Registrations') {
      typeOfWorkVal = typeOfWorkVal.slice(0, -1);
    }
    return {
      control_number: {
        value: esr.get('control_number'),
      },
      full_title: {
        value: esr.getTitle(true),
        url: esr.getHref(),
      },
      copyright_number: {
        label: esr.getCopyrightNumberLabel(),
        value: esr.getCopyrightNumber(),
      },

      // this needs to have a custom label dependeant on record type
      representative_date: {
        label: esr.isRegistration() ? 'Date' : 'Date of Recordation',
        value: esr.get('representative_date'),
        visible: esr.isRecordation() || esr.isRegistration(),
      },
      acquisition_type: {
        value: esr.get('type_of_acquisition'),
        visible: esr.isAcquisition(),
      },
      type_of_work: {
        label: 'Type of Work',
        value: typeOfWorkVal,
      },

      // this also needs a custom label between Party 1 / Owner if GATT
      party_1: {
        label: esr.isGATT() ? 'Owner' : 'Party 1',
        value: esr.getPartyOne(),
      },
      publisher_name: {
        label: 'Publisher Name',
        value: esr.isCardCatalog() ? null : esr.getPublisherName(),
      },
      claimant: {
        label: 'Claimant',
        value: esr.getClaimant(),
      },

      // this also needs a custom label between Party 2 / Author if GATT
      party_2: {
        label: esr.isGATT() ? 'Author' : 'Party 2',
        value: esr.getPartyTwo(),
      },
      service_request_number: {
        label: 'Service Request Number',
        value: esr.get('service_request_number'),
        visible: esr.isRegistration() && this.envService.internal,
      },
      url: {
        value: esr.getHref(),
      },
      timestamp: {
        value: esr.get('saved_on'),
      },
      unix_timestamp: {
        value: esr.get('unix_timestamp')
      },
      simple_record: {
        value: esr.getSimpleRecord(esr),
      },
      type_of_record: {
        value: esr.get('type_of_record'),
      },
      date_viewed: {
        value: null,
      },
    } as CprsRecentRecordData;
  }
}
