<div class="cprs-facet-chips">
  <div class="row">
    <cprs-module-chip-list [formArray]="this.stateService.system_of_origin" (remove)="facet()"></cprs-module-chip-list>
    <cprs-module-chip-list [formArray]="this.stateService.type_of_record" (remove)="facet()"></cprs-module-chip-list>
  </div>
  <div class="row">
    <cprs-module-chip-list
      [formArray]="this.stateService.registration_status"
      (remove)="facet()"
    ></cprs-module-chip-list>
    <cprs-module-chip-list
      [formArray]="this.stateService.registration_class"
      (remove)="facet()"
    ></cprs-module-chip-list>
    <cprs-module-chip-list [formArray]="this.stateService.type_of_work" (remove)="facet()"></cprs-module-chip-list>
  </div>

  <div class="row">
    <cprs-module-chip-list
      [formArray]="this.stateService.recordation_item_type"
      (remove)="facet()"
    ></cprs-module-chip-list>
  </div>

  <div class="row">
    <cprs-module-chip-list
      [formArray]="this.stateService.type_of_acquisition"
      (remove)="facet()"
    ></cprs-module-chip-list>
  </div>
  <div class="row">
    <cprs-module-chip-list
      [formArray]="this.stateService.acquisition_item_type"
      (remove)="facet()"
    ></cprs-module-chip-list>
  </div>
</div>
