<form [formGroup]="localFormGroup" class="container">
  <div class="row">
    <div class="d-flex col me-4">
      <!-- <input type="checkbox" formControlName="all_records" /> -->
      <cd-input-crs [label]="'All records'" formControlName="all_records" (change)="change(true)"></cd-input-crs>
    </div>

    <div class="d-flex col me-4">
      <cd-input-crs [label]="'Registration'" formControlName="registration" (change)="change(false)"></cd-input-crs>
    </div>

    <div class="d-flex col me-4">
      <cd-input-crs [label]="'Recordation'" formControlName="recordation" (change)="change(false)"></cd-input-crs>
    </div>

    <div *ngIf="this.envService.internal" class="d-flex col me-4">
      <cd-input-crs [label]="'Acquisition'" formControlName="acquisition" (change)="change(false)"></cd-input-crs>
    </div>
  </div>
</form>
