<cd-page-template>
  <div data-layout="header-content">
    <cd-header [productName]="header.productName" breakpoint="xl" (skipToMain)="skipToMain()">
      <ng-container ngProjectAs="[responsive]">
        <ng-container *ngFor="let link of header.siteLinks">
          <li
            [cdHeaderLink]="link.url"
            [active]="isActive(link.url)"
            [attr.aria-current]="getAriaCurrent(link.url, router.url)"
          >
            {{ link.label }}
          </li>
        </ng-container>
      </ng-container>
      <ng-container ngProjectAs="[fixed]" *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
        <li [placement]="'bottom-end'" [autoClose]="'mouseleave'" [cdHeaderMenu]="'User account'">
          <span>
            <cd-icon [cdsIcon]="'user'" [classes]="'fs-5'"></cd-icon>
          </span>
          <ul #cdDropdownTarget>
            <li *ngIf="!isLoggedIn" (cdMenuTextButton)="login()">Login</li>
            <li *ngIf="isLoggedIn" (cdMenuTextButton)="logout($event)">Logout</li>
          </ul>
        </li>
      </ng-container>
    </cd-header>
  </div>
  <main data-layout="main-content" id="main-content" tabindex="-1">
    <app-popup-survey></app-popup-survey>
    <router-outlet></router-outlet>
  </main>
  <div data-layout="footer-content">
    <cd-footer>
      <div class="container g-0 footer-content-section">
        <div class="row">
          <div class="col-md col-6">
            <h2 class="fs-5 text-white display-3">{{ footerLinksOne.label }}</h2>
            <ul class="list-unstyled">
              <li *ngFor="let link of footerLinksOne.links">
                <a [routerLink]="link.link" class="text-white cd-legal">
                  {{ link.label }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md col-6">
            <h2 class="fs-5 text-white display-3">{{ footerLinksTwo.label }}</h2>
            <ul class="list-unstyled">
              <li *ngFor="let link of footerLinksTwo.links">
                <a [href]="link.link" class="text-white cd-legal">{{ link.label }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md col-6 contact-section">
            <h2 class="fs-5 text-white display-3">{{ footerLinksThree.label }}</h2>
            <ul class="list-unstyled">
              <li *ngFor="let link of footerLinksThree.links">
                <a [href]="link.link" class="text-white cd-legal">{{ link.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="disclaimer">
        <div style="font-size: 14px; margin: 3px">
          <strong>DISCLAIMER</strong>
          The Copyright Public Records System (CPRS) pilot is under development and is not the final version of the
          CPRS. This pilot does not replace or supersede the online
          <a rel="noopener noreferrer" href="https://cocatalog.loc.gov" target="_blank">public catalog</a> or existing
          search practices the Copyright Office has established. Results obtained through the CPRS pilot should not be
          used for legal purposes. For information on searching copyright records, see
          <a rel="noopener noreferrer" href="https://www.copyright.gov/circs/circ22.pdf"
            >How to Investigate the Copyright Status of a Work (Circular 22)</a
          >. For information on removing personal information from Copyright Office public records, refer to
          <a rel="noopener noreferrer" href="https://www.copyright.gov/circs/circ18.pdf" target="_blank"
            >Privacy: Public Copyright Registration Records (Circular 18)</a
          >.
        </div>
      </div>
    </cd-footer>
  </div>
</cd-page-template>
