import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ICdHeader } from '../interfaces/header.interface';
import { Subscription } from 'rxjs/internal/Subscription';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { KeycloakService } from 'keycloak-angular';
import { CustomAuthGuardService } from '@src/app/cprs/modules/system/guards/custom-auth-guard.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cprs-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageTemplateComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  readonly header: ICdHeader = {
    productName: 'Public Records System',
    skipToMain: this.skipToMain(),
    controlId: 'cprs-navbar',
    controlLabel: 'Public Records System',
    siteLinks: [
      {
        url: '/',
        label: 'Home',
      },
      {
        url: '/recent-records',
        label: 'Recent Records',
      },
      {
        url: '/recent-searches',
        label: 'Recent Searches',
      },
      {
        url: '/name-directory',
        label: 'Name Directory',
      },
      {
        url: '/help',
        label: 'Help',
      },
    ],
  };

  footerLinksOne: { label: string; links: Array<{ label: string; link: string }> } = {
    label: 'Menu',
    links: [
      {
        label: 'Home',
        link: '../',
      },
      {
        label: 'Recent Records',
        link: '../recent-records',
      },
      {
        label: 'Recent Searches',
        link: '../recent-searches',
      },
      {
        label: 'Name Directory',
        link: '../name-directory',
      },
      {
        label: 'Help',
        link: '../help',
      },
    ],
  };

  footerLinksTwo: { label: string; links: Array<{ label: string; link: string }> } = {
    label: 'Resources',
    links: [
      {
        label: 'Request a Copy Estimate',
        link: 'https://www.copyright.gov/forms/copy_estimate.html',
      },
      {
        label: 'Request a Search Estimate',
        link: 'https://www.copyright.gov/forms/search_estimate.html',
      },
      {
        label: 'FAQs',
        link: 'https://www.copyright.gov/help/faq/',
      },
    ],
  };

  footerLinksThree: { label: string; links: Array<{ label: string; link: string }> } = {
    label: 'Contact',
    links: [
      {
        label: 'Contact Us',
        link: 'https://www.copyright.gov/help/',
      },
      {
        label: 'Feedback',
        link: 'https://www.research.net/r/CPRS-feedback',
      },
    ],
  };

  menuLinks = {
    label: 'Menu',
    links: [
      {
        label: 'Home',
        link: '../',
      },
      {
        label: 'Recent Records',
        link: '../recent-records',
      },
      {
        label: 'Recent Searches',
        link: '../recent-searches',
      },
      {
        label: 'Name Directory',
        link: '../name-directory',
      },
      {
        label: 'Help',
        link: '../help',
      },
    ],
  };

  resourcesLinks = {
    label: 'Resources',
    links: [
      {
        label: 'Request Copies',
        link: 'https://www.copyright.gov/circs/circ06.pdf',
      },
      {
        label: 'Get a Search Estimate',
        link: 'https://www.copyright.gov/forms/search_estimate.html',
      },
      {
        label: 'FAQs',
        link: 'https://www.copyright.gov/help/faq/',
      },
    ],
  };

  contactLinks = {
    label: 'Contact',
    links: [
      {
        label: 'Contact Us',
        link: 'https://www.copyright.gov/help/',
      },
      {
        label: 'Feedback',
        link: 'https://www.research.net/r/CPRS-feedback',
      },
    ],
  };

  public isLoggedIn = false;

  constructor(
    public router: Router,
    public featureFlagService: FeatureFlagService,
    public customAuthguard: CustomAuthGuardService,
    public envService: EnvService,
    public keycloakService: KeycloakService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const saved = {
      label: 'Saved',
      url: '/saved',
      link: '../saved',
    };

    this.customAuthguard.loggedIn().subscribe(logged => {
      if (logged) {
        this.featureFlagService.isSavedRecordSearchEnabled().subscribe((enabled) => {
          if (enabled) {
            if (this.header.siteLinks) {
              this.header.siteLinks.splice(1, 0, saved);
            }

            this.menuLinks.links.splice(1, 0, saved);
            this.footerLinksOne.links.splice(1, 0, saved);
          }
        });
      }
    })
  }

  ngOnInit(): void {
    const subscription = this.customAuthguard.isAuthenticated.subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
    this.subscriptions.push(subscription);

    this.header.productName = this.envService.internal
      ? this.header.productName + ' - Staff Catalog'
      : this.header.productName;
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }

  skipToMain() {
    // this.document.getElementsByTagName('main')[0].focus();
    this.document.getElementsByTagName('main')[0]?.focus();
  }

  login() {
    this.router.navigate(['/disclaimer']).then();
  }

  logout(_event: Event) {
    this.customAuthguard.logout();
  }

  getAriaCurrent(linkUrl: any, routerUrl: any) {
    if (linkUrl === '/saved' && (routerUrl === '/saved/saved-records' || routerUrl === '/saved/saved-searches')) {
      return 'page';
    }
    if (linkUrl === '/name-directory') {
      const queryIndex = routerUrl.indexOf('?');
      const path = queryIndex === -1 ? routerUrl : routerUrl.slice(0, queryIndex);
      return path === linkUrl ? 'page' : null;
    }

    return linkUrl === routerUrl ? 'page' : null;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
