import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { CprsService } from '../../services/cprs.service';

@Component({
  selector: 'cprs-record-type-selection',
  templateUrl: './record-type-selection.component.html',
  styleUrls: ['./record-type-selection.component.scss'],
})
export class RecordTypeSelectionComponent {
  @Output() recordTypeSelected = new EventEmitter();

  public formArray: UntypedFormArray;

  public localFormGroup = new FormGroup({
    all_records: new FormControl(true),
    registration: new FormControl(false),
    recordation: new FormControl(false),
    acquisition: new FormControl(false)
  })

  constructor(public stateService: StateService, public envService: EnvService, public cprsService: CprsService) {
    this.formArray = this.stateService.selectionLists.get('type_of_record') as UntypedFormArray;

    this.formArray.valueChanges.subscribe((checkboxes: { label: string; value: string; selected: boolean }[]) => {
      const anySelected = checkboxes.some((v) => v.selected);
      if (anySelected && this.localFormGroup.value.all_records) {
        // this.all_records.setValue(false);
        this.localFormGroup.get('all_records')?.setValue(false);
      }

      if (!anySelected && !this.localFormGroup.value.all_records) {
        this.localFormGroup.get('all_records')?.setValue(true);
      }

      checkboxes.forEach(cb => {
        if (this.localFormGroup.contains(cb.value) && cb.selected !== this.localFormGroup.get(cb.value)?.value) {
          this.localFormGroup.get(cb.value)?.setValue(cb.selected);
        }
      })
    });
  }

  change(isAllSelected: any) {
    if (isAllSelected) {
      this.formArray.controls.forEach((ctrl) => ctrl.get('selected')?.setValue(false));
    } else {
      this.formArray.controls.forEach((group: FormGroup) => {
        const value = group.get('value')?.value;
        const selected = group.get('selected')?.value;

        if (selected !== this.localFormGroup.get(value)?.value) {
          group.get('selected')?.setValue(this.localFormGroup.get(value)?.value)
        }
      });
    }

    this.stateService.pagination.get('page_number')?.setValue(1);
    if (this.cprsService.advancedSearchPreformed) {
      this.cprsService.search('advanced');
    }
  }
}
