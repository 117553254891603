import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { SearchResultService } from '@src/app/cprs/shared/search-results/search-result.service';
import { StateService } from '@src/app/cprs/state/state.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { CdSearchService } from '@src/app/cprs/services/search.service';
import { ParametersService } from '@src/app/cprs/services/parameters.service';
import { Page } from '@cop/design/services';

@Component({
  selector: 'cprs-module-facet-sidebar',
  templateUrl: './facet-sidebar.component.html',
  styleUrls: ['./facet-sidebar.component.scss'],
})
export class FacetSidebarComponent implements OnInit {
  public available_facets = [
    { label: 'Collection', value: 'system_of_origin', visible: true },
    { label: 'Record Type', value: 'type_of_record', visible: true },
    { label: 'Registration Status', value: 'registration_status', visible: true },
    { label: 'Registration Class', value: 'registration_class', visible: true },
    { label: 'Registration Item Type', value: 'type_of_work', visible: true },
    { label: 'Recordation Document Type', value: 'recordation_item_type', visible: true },
    { label: 'Type of Acquisition', value: 'type_of_acquisition', visible: true },
    { label: 'Acquisition Item Type', value: 'acquisition_item_type', visible: true },
  ];

  public doNotUpdate: string;

  public current_search: CprsSearch;

  public page: Page<any>;

  public histogram: any;

  constructor(
    public stateService: StateService,
    public cdSearchService: CdSearchService,
    public parameterService: ParametersService,
    public cprsService: CprsService,
    public searchResultService: SearchResultService,
    public envService: EnvService
  ) { }

  ngOnInit(): void {
    this.cprsService.searchPage.subscribe((page) => {
      this.page = page;
    });
    // not implemented
    this.cprsService.getCurrentSearch().subscribe((current_search) => {
      this.current_search = current_search;
      this.updateHistogram();
    });
    this.cprsService.onFacet.subscribe((current_search) => {
      if (!current_search) {
        return;
      }

      this.current_search = current_search;

      this.updateHistogram();
    });
  }

  updateHistogram() {
    this.histogram = this.current_search.response.metadata.histogram;

    // grab keys from response
    const filtered_keys = Object.keys(this.histogram.filtered);
    // get the form groups that are associated with the keys
    filtered_keys.forEach((key) => {
      const formArray: UntypedFormArray = this.stateService[key];
      if (key === this.doNotUpdate) {
        return;
      }
      if (formArray) {
        formArray.controls.forEach((control) => {
          // we should not update high level facets (type_of_record, system_of_origin);
          // or they should just come frm the unfiltered key
          // OKAY last HISTOGRAM FEATURE NEEDED!
          // If you click a certain selection list, do not update that facet set
          // or more generically, if that selection list contain any selected items, do not update

          const ctrlValue = control.get('value')?.value;
          const currentValue = control.get('histogram')?.value;
          let actualValue = this.histogram.filtered[key][ctrlValue];
          if (key === 'system_of_origin' || key === 'type_of_record') {
            actualValue = this.histogram.unfiltered[key][ctrlValue];

            // if sysytem of origin selected, overwrite type of record
            if (key === 'type_of_record' && this.doNotUpdate === 'system_of_origin') {
              actualValue = this.histogram.filtered[key][ctrlValue];
            }
          }

          if (key === 'type_of_record') {
            actualValue = this.histogram.filtered[key][ctrlValue];
          }

          const hasSelected = formArray.controls.map((ctrl) => ctrl.get('selected')?.value).filter(Boolean);

          if (currentValue !== actualValue && hasSelected.length > 0) {
            if (!actualValue) {
              actualValue = this.histogram.unfiltered[key][ctrlValue];
              control.get('histogram')?.setValue(actualValue);
              return;
            }

            control.get('histogram')?.setValue(actualValue);
            return;
          }

          control.get('histogram')?.setValue(actualValue);
        });

        // resort
        formArray.controls.sort((a, b) => {
          const histogramA = a?.get('histogram')?.value || 0;
          const histogramB = b?.get('histogram')?.value || 0;
          return histogramB - histogramA;
        });
      }
    });
    this.doNotUpdate = '';
  }

  applyDatepicker() {
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);
    this.cprsService.$page.number = 0;
    this.cprsService.$page.size = 10;
    this.cprsService.searchPage.next(this.cprsService.$page);

    this.cprsService.search(this.current_search?.type);
  }

  clearDatepicker() {
    this.cprsService.search(this.current_search?.type);
  }

  toggleAccordions() {
    this.searchResultService.toggleSelectionList();
  }

  clearSelectionLists() {
    this.stateService.clearSelectionLists();
    this.stateService.pagination.get('page_number')?.setValue(Number(1));
    this.cprsService.search(this.current_search?.type);
  }

  expandIcon() {
    return this.searchResultService.expandedState === 'Collapse all' ? 'minus' : 'plus';
  }

  selectionChange(checked: boolean, facet: { label: string; value: string }) {
    if (checked) {
      this.doNotUpdate = facet.value;
    } else {
      this.doNotUpdate = '';
    }
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);
    this.page.number = 0;
    this.page.size = 10;
    this.cprsService.searchPage.next(this.page);
    // this.pagingService.page.pageStart = 0;
    // this.pagingService.page.pageSize = 10;
    // this.cprsService.search();

    this.cprsService.facet(this.current_search?.type);

    // this.cdSearchService.simpleSearch(params).subscribe(resp => {

    //   const esrs = resp.data.map(json => new ElasticSearchResult(json));

    //   this.cprsService.testSubject.next(esrs);
    // });
    // this.cprsService.test();
  }

  isExpanded(key: string): boolean {
    return !!this.searchResultService.getSelectionListControl(key)?.expanded;
  }

  getFormArray(key: string): UntypedFormArray {
    return this.stateService.selectionLists.get(key) as UntypedFormArray;
  }
}
