import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdvancedSearchComponent as CprsAdvSearch } from './cprs/pages/advanced-search/advanced-search.component';

// import { ApplicationCardComponent } from '@search/application-card/application-card.component';
// import { DetailedRecordComponent } from '@search/detailed-record/detailed-record.component';
// import { AccountSetupComponent } from '../../dead_code/shared_components/accounts/account-setup/account-setup.component';
import { PageNotFoundComponent } from '@src/app/cprs/modules/system/pages/page-not-found/page-not-found.component';
import { SessionExpiredComponent } from '@src/app/cprs/modules/system/pages/session-expired/session-expired.component';
import { SystemAnnouncementsComponent } from '@components/system-announcements/system-announcements.component';
import { MaintenanceGuard } from '@src/app/cprs/modules/system/guards/maintenance.guard.service';
import { SimpleSearchComponent } from './cprs/pages/simple-search/simple-search.component';
import { HomePageComponent } from './cprs/pages/home-page/home-page.component';
import { RecentSearchesPageComponent } from './cprs/pages/recent/recent-searches-page/recent-searches-page.component';
import { RecentRecordsPageComponent } from './cprs/pages/recent/recent-records-page/recent-records-page.component';
import { HelpPageComponent } from './cprs/pages/help-page/help-page.component';
import { ManageSaveComponent } from './cprs/pages/saved/manage-save.component';
// import { SavedRecordsPageComponent } from './cprs/pages/saved/saved-records-page/saved-records-page.component';
// import { SavedSearchesPageComponent } from './cprs/pages/saved/saved-searches-page/saved-searches-page.component';
import { SavedRecordsComponent } from './cprs/modules/saved/components/saved-records/saved-records.component';
import { SavedSearchesComponent } from './cprs/modules/saved/components/saved-searches/saved-searches.component';
import { UnsubscribePageComponent } from './cprs/pages/unsubscribe-page/unsubscribe-page.component';
import { NameDirectoryPageComponent } from './cprs/pages/name-directory-page/name-directory-page.component';
import { AssociatedRecordsPageComponent } from './cprs/pages/associated-records-page/associated-records-page.component';
// import { SavedSearchesPageComponent } from './cprs/pages/saved/saved-searches-page/saved-searches-page.component';
// import { SavedRecordsPageComponent } from './cprs/pages/saved/saved-records-page/saved-records-page.component';
// import { DisclaimerModule } from '@cop/disclaimer';
import { CustomAuthGuardService } from './cprs/modules/system/guards/custom-auth-guard.service';
import { DetailedRecordPageComponent } from './cprs/pages/detailed-record-page/detailed-record-page.component';
import { DisclaimerModule } from '@cop/disclaimer';
import { appDisclaimerConfig } from './disclaimer-config';
// import { appDisclaimerConfig } from './disclaimer-config';

/*
  CPRS Public Records
    First Level:
      - / (home page)
      - /search (simple search)
      - /advanced-search
      - /recent-records
      - /recent-searches
      - /saved-records
      - /saved-searches
      - /name-directory
      - /detailed-record (shared DRV component)
      - /application (shared DRV component)
      - /help
      - /disclaimer (library)

    Second level
      - /detailed-record/{$control_number}
      - /detailed-record/{$control_number}
      - /name-directory/{$name_token}/associated-records
*/
const routes: Routes = [
  {
    component: DetailedRecordPageComponent,
    data: {
      heading: 'Detailed Record Page | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'drv/:id',
  },
  {
    component: NameDirectoryPageComponent,
    data: {
      heading: 'Name Directory | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'name-directory',
  },
  {
    component: NameDirectoryPageComponent,
    data: {
      heading: 'Name Directory | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'name-directory/:id',
  },
  {
    component: AssociatedRecordsPageComponent,
    data: {
      heading: 'Associated Records | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'name-directory/:id/associated-records',
  },
  {
    canActivate: [MaintenanceGuard],
    component: CprsAdvSearch,
    data: {
      heading: 'Advanced Search | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'advanced-search',
  },
  {
    component: SimpleSearchComponent,
    data: {
      heading: 'Simple Search | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'search',
  },
  {
    canActivate: [MaintenanceGuard, CustomAuthGuardService],
    component: HomePageComponent,
    data: {
      heading: 'Home | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: '',
  },
  // END OF NEW ROUTES
  // {
  //   canActivate: [MaintenanceGuard],
  //   component: LandingComponent,
  //   data: {
  //     heading: 'U.S. Copyright Office Public Records System',
  //     roles: [],
  //   },
  //   path: '',
  // },
  {
    path: 'disclaimer',
    canActivate: [CustomAuthGuardService],
    loadChildren: () =>
      DisclaimerModule.asChild(
        appDisclaimerConfig
      ),
  },
  //
  // {
  //   canActivate: [MaintenanceGuard],
  //   component: AccountSetupComponent,
  //   data: {
  //     heading: 'U.S. Copyright Office Public Records System',
  //     roles: [],
  //   },
  //   path: 'accounts/setup',
  // },
  {
    canActivate: [MaintenanceGuard],
    component: HelpPageComponent,
    data: {
      heading: 'Help | U.S. Copyright Public Records System',
      roles: [],
    },
    path: 'help',
    pathMatch: 'full',
  },
  {
    canActivate: [MaintenanceGuard],
    component: PageNotFoundComponent,
    data: {
      heading: 'U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'page-not-found',
  },
  {
    canActivate: [MaintenanceGuard],
    component: SystemAnnouncementsComponent,
    data: {
      heading: 'U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'announcements',
  },
  {
    canActivate: [MaintenanceGuard],
    component: SessionExpiredComponent,
    data: {
      roles: [],
    },
    path: 'session-expired',
  },
  // {
  //   canActivate: [MaintenanceGuard],
  //   component: BasicSearchComponent,
  //   data: {
  //     heading: 'Search | U.S. Copyright Office Public Records System',
  //     roles: [],
  //   },
  //   path: 'search',
  //   pathMatch: 'full',
  // },
  // {
  //   canActivate: [MaintenanceGuard],
  //   component: AdvancedSearchComponent,
  //   data: {
  //     heading: 'Advanced Search | U.S. Copyright Public Records System',
  //     roles: [],
  //   },
  //   path: 'advanced-search',
  // },
  // {
  //   canActivate: [MaintenanceGuard, FeatureGuard],
  //   component: SavedRecordsComponent,
  //   data: {
  //     featureName: 'save_record_search',
  //     heading: 'U.S. Copyright Office Public Records System',
  //     roles: [],
  //   },
  //   path: 'saved-records',
  // },
  {
    canActivate: [MaintenanceGuard],
    component: RecentRecordsPageComponent,
    data: {
      heading: 'Recent Records | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'recent-records',
  },
  {
    canActivate: [MaintenanceGuard],
    component: RecentSearchesPageComponent,
    data: {
      heading: 'Recent Searches | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'recent-searches',
  },
  {
    canActivate: [MaintenanceGuard],
    component: ManageSaveComponent,
    data: {
      featureName: 'save_record_search',
      roles: [],
    },
    path: 'saved',
    children: [
      {
        path: 'saved-records',
        component: SavedRecordsComponent,
      },
      {
        path: 'saved-searches',
        component: SavedSearchesComponent,
      },
    ],
  },
  {
    canActivate: [MaintenanceGuard],
    component: DetailedRecordPageComponent,
    data: {
      heading: 'Detailed Record View | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'detailed-record/:id',
  },
  {
    canActivate: [MaintenanceGuard],
    component: DetailedRecordPageComponent,
    data: {
      featureName: 'application_card',
      heading: 'Application Card View | U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'application-card/:id',
  },
  {
    canActivate: [MaintenanceGuard],
    component: UnsubscribePageComponent,
    data: {
      heading: 'U.S. Copyright Office Public Records System',
      roles: [],
    },
    path: 'unsubscribe',
  },
  {
    canActivate: [MaintenanceGuard],
    component: UnsubscribePageComponent,
    data: {
      heading: 'U.S. Copyright Office Public Records System',
      roles: [],
      state: 'resubscribe',
    },
    path: 'unsubscribe-undo',
  },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
