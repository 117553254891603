import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, Subscription } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { DialogService } from '../../dialogs/services/dialog.service';

@Injectable()
export class IdleService implements OnDestroy {
  subscriptions: Subscription[] = [];
  public startToIdle = 15; // 15 seconds before idle timeout starts
  public idleTimeout = 1800; // Set to 30 minutes

  private timers: Map<string, { timerId: any, lastActivityTime: number, subject: Subject<void>, timer: any }> = new Map();

  constructor(
    private router: Router,
    public keycloakService: KeycloakService,
    public dialogService: DialogService
  ) {}

  // ngIdle can't be provided to create two separate instances
  // https://github.com/moribvndvs/ng-idle/issues/142
  init() {
    this.createInactivityTimer(
      'inactivity',
      1000,
      0.5 * 60 * 60 * 1000, // 4 hours
      'session'
    ).subscribe(() => {
      this.keycloakService.isLoggedIn().then(loggedIn => {
        if (loggedIn) {
          this.router.navigateByUrl('/').then(() => {
            this.keycloakService.logout('');
          });
        } else {
          this.router.navigateByUrl('/').then(() => {
            this.dialogService.disclaimer();
          });
        }
      });
    });
  }

  public userActivity(): void {
    sessionStorage.setItem('cprs-last-activity-time', JSON.stringify(Date.now()));
    localStorage.setItem('cprs-last-activity-time', JSON.stringify(Date.now()));
  }

  public getSessionUserActivity() {
    return sessionStorage.getItem('cprs-last-activity-time');
  }

  public getLocalUserActivity() {
    return localStorage.getItem('cprs-last-activity-time');
  }

  public getUserActivity(storageType: 'local' | 'session') {
    if (storageType === 'local') {
      return this.getLocalUserActivity();
    } else {
      return this.getSessionUserActivity();
    }
  }

  // okay so we will have a start idle time and the total inactivity period
  // we should be able to create an inactivity timer that
  public createInactivityTimer(
    timerId: string,
    pollingTime: number = 1000,
    inactivityPeriod: number,
    storageType: 'local' | 'session' = 'local'
  ): Observable<void> {
    if (this.timers.has(timerId)) {
      throw new Error(`Timer with ID ${timerId} already exists.`);
    }

    // create the subject
    const timerSubject = new Subject<void>();

    // last activity is now
    const lastActivity = Date.now();

    // set our timer
    const timer = {
      timerId,
      subject: timerSubject,
      lastActivityTime: lastActivity,
      timer: setInterval(() => {
        const lastActivityTime = this.getUserActivity(storageType);
        if (lastActivityTime) {
          const parsedLastActivityTime = JSON.parse(lastActivityTime);

          // Validate if parsedLastActivityTime is a number
          if (typeof parsedLastActivityTime === 'number') {
            const currentTime = Date.now();
            const elapsedTime = currentTime - parsedLastActivityTime;
            const afkThreshold = inactivityPeriod; // e.g., 4 hours in milliseconds

            if (elapsedTime >= afkThreshold) {
              timerSubject.next();
            }
          }
        }
      }, pollingTime)
    }

    this.timers.set(timerId, timer);

    return timerSubject.asObservable();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
