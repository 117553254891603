import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerDates extends VoyagerHelper {
    get creation_date_as_year(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Recordation', 'recordation_date');
    }

    get date_creation_date(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Creation', 'date_creation_date');
    }

    get date_of_first_publication(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Publication', 'date_of_first_publication');
    }

    get preregistration_date_of_anticipated_completion(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Projected Date of Publication', 'preregistration_date_of_anticipated_completion');
    }

    get date_first_exploitation_date(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of First Exploitation', 'date_first_exploitation_date');
    }

    get in_notice_date(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date in Notice', 'in_notice_date');
    }

    // DOES NOT EXIST IN VOYAGER
    get representative_date_for_deposit(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Representative Date for Deposit', 'date_representative_date_for_deposit');
    }

    // DOES NOT EXIST IN VOYAGER
    get deposit_date_offered_to_lc(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Deposit Date Offered to LC', 'deposit_date_offered_to_lc');
    }

    get type_of_dates(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Type of Date(s)', '');
    }

    // DOES NOT EXIST IN VOYAGER
    get date_record_entered_on_file_as_date(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date Record Entered on File', 'date_record_entered_on_file_as_date', ['internal']);
    }

    get deposit_receipt_date(): VoyagerProperty {
        return this.createVoyagerProperty('Deposit Receipt Date', 'deposits_list', ['deposit_receipt_date'], ['internal']);
    }

    get first_exploitation_date_coded(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('First Exploitation Date Coded', 'first_exploitation_date_coded', ['internal']);
    }

    get latest_transaction_date_and_time(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Latest Transaction Date and Time', 'latest_transaction_date_and_time', ['internal', 'no-export']);
    }

    get recordation_date(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Recordation', 'recordation_date');
    }

    get date_of_publication(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Publication', ['publication_date', 'publication_date_as_date']);
    }

    get date_of_certification(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Certification', 'date_certification_date_statement');
    }

    get date_of_execution(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Execution', 'execution_date');
    }
}
